import React, { Component } from 'react';

import Root from './js/Root';
import './css/App.css';

class App extends Component {
  render() {
    return (
      <Root />
    );
  }
}

export default App;
