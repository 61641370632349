import React, { Component } from "react";
import { Nav } from "./Bundler"

import '../css/root.css'

class Root extends Component {
  constructor() {
    super();
    this.state = {

    };

  }

  render() {
    const { seo_title } = this.state;
    return (
      <div className="root-container">
        <div className="content-container">
          <Nav />
          <div className="body-container">
            <div className="tagline">Software Engineering Intern, Google</div>
            <div className="tagline">UCLA c/o 2019</div>

            <div id="border-rect"></div>

            <ul>
              <li><div className="link"><a href="https://linkedin.com/in/unmarshall">linkedIn</a></div></li>
              <li><div className="link"><a href="https://github.com/unmrshl">github</a></div></li>
              <li>
                <div className="link">current projects:</div>
                <ul>
                  <div className="link"><a href="https://findyourtotem.net">Find Your Totem</a></div>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="contact-container">
          <div>m@unmarshalled.com</div>
          <div>Copyright Marshall Briggs, 2018. All rights reserved.</div>
        </div>
      </div>
    );
  }
}

export default Root;
