import React, { Component } from "react";

class Nav extends Component {
  render() {
    return (
      <div className="nav-bar">
        <div className="nav-background"/>

        <div className="hamburger">
          <div className="hamburger-line" />
          <div className="hamburger-line" />
          <div className="hamburger-line" />
        </div>

        <div id="title">marshall briggs</div>
      </div>
    );
  }
}

export default Nav;
